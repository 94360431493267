import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import BtmFootInplay from "../assets/images/icons/home/btmFootInplay.png";
import BtmFootSports from "../assets/images/icons/home/btmFootSports.png";
import BtmFootHome from "../assets/images/icons/home/btmFootHome.png";
import BtmFootCasino from "../assets/images/icons/home/btmFootCasino.png";
import BtmFootProfile from "../assets/images/icons/home/btmFootProfile.svg";
import { useSelector } from "react-redux";

export default function BottomNav() {
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const { isAuth } = useSelector((state) => state.auth);

  const footerPages = [
    "sportsbook",

    "home",
    "live-dealer",
    "profile",
    "sportsbook",
    "casino/evolution",
    "casino/ezugi",
    "public-inplay",
    "public-sports",
    "/",
    "sports",
    "betby",
  ];
  useEffect(() => {
    const matchedValue = footerPages.find((f) => {
      if (f === "public-sports") {
        return location?.pathname?.split("/")?.[1] === "public-sports";
      } else {
        return location?.pathname.includes(f);
      }
    });
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <div>
      <footer className="d-lg-none">
        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li className="inplay">
              <a
                className={
                  activePage === "sportsbook" || activePage === "public-inplay"
                    ? "active"
                    : ""
                }
                href={isAuth ? "/sportsbook/Inplay" : "/public-inplay"}
              >
                <div className="imgBox">
                  <img src={BtmFootInplay} alt="footer nav icon" />
                </div>
                <span>In play</span>
              </a>
            </li>
            <li className="sports">
              <a
                className={
                  activePage === "betby" || activePage === "public-sports"
                    ? "active"
                    : ""
                }
                href={isAuth ? "/betby" : "/sign-in"}
              >
                <div className="imgBox">
                  <img src={BtmFootSports} alt="footer nav icon" />
                </div>
                <span>Sports</span>
              </a>
            </li>
            <li className="home">
              <a
                className={
                  activePage === "sports" || activePage === "/" ? "active" : ""
                }
                href="/sports"
              >
                <div className="imgBox">
                  <img src={BtmFootHome} alt="footer nav icon" />
                </div>
                <span>Home</span>
              </a>
            </li>
            <li className="casino">
              <a
                className={activePage === "live-dealer" ? "active" : ""}
                href={isAuth ? "/live-dealer" : "/live-dealer-public"}
              >
                <div className="imgBox">
                  <img src={BtmFootCasino} alt="footer nav icon" />
                </div>
                <span>Casino</span>
              </a>
            </li>
            <li className="profile">
              <a
                className={activePage === "profile" ? "active" : ""}
                href="/profile"
              >
                <div className="imgBox">
                  <img src={BtmFootProfile} alt="footer nav icon" />
                </div>
                <span>Profile</span>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}
